import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
// import BlogFeatured from "../containers/blog/blog-featured";
import PrivacyPolicy from "../containers/blog/privacy-policy";

const Blog = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Privacy Policy | Zeurgies</title>
        <meta
          name="description"
          content="This policy document lays down the way we safely handle the personal information of our website visitors."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Privacy Policy" />
        {/* blog featured */}
        {/* <BlogFeatured /> */}
        {/* blog post with sidebar */}
        <PrivacyPolicy />
      </LayoutOne>
    </Fragment>
  );
};

export default Blog;
