import React from "react";
// import LocationMap from "../../../components/contact/contact-map";
import styles from "./ContactMap.module.scss";

const ContactMap = () => {
  return (
    <div className={`${styles.voopoAddress} bg--cart-10`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-5">
            <div className={styles.vpContactAddress}>
              <h2>Out Contact Info</h2>
              <div className="vp__address__container">
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Address</h4>
                  <p>Chinachem Golden Plaza,</p>
                  <p>77 Mody Rd, Tsim Sha Tsui, Hong Kong</p>
                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Address</h4>
                  <p>
                    <a href="mailto:info@zeurgies.com">info@zeurgies.com</a>
                  </p>
                </div>
                {/* End Single Address */}
                {/* Start Single Address */}
                <div className={styles.vpAddress}>
                  <h4>Address</h4>
                  <p>
                    <a href="tel:+852 23115832">+852 23115832</a>
                  </p>
                </div>
                {/* End Single Address */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.googleMap}>
        {/* location map */}
        {/* <LocationMap latitude="47.444" longitude="-122.176" /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d922.854602367222!2d114.17829706942017!3d22.300010411378516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ef65af409b%3A0xff029e9c7b36bd7d!2sChinachem%20Golden%20Plaza%2C%2077%20Mody%20Rd%2C%20Tsim%20Sha%20Tsui%2C%20Hong%20Kong!5e0!3m2!1sen!2sin!4v1728114120564!5m2!1sen!2sin"
          loading="lazy"
          title="map"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap;
