import React from "react";
// import styles from "./PostDetailsWithSidebar.module.scss";

const PostDetailsWithSidebar = () => {
  return (
    <div className="blog__details__area pt--120 pb--110 bg--white">
      <div className="container">
        <div className="row">
          <div>
            <div>
              <div style={{ textAlign: "justify" }}>
                <h2 style={{ fontSize: "40px" }}>Cookies Policy of Zeurgies</h2>
                <p>
                  On our website at Zeurgies, we endeavor to make your visit
                  with us smooth and tailored. To help us do this, we utilize
                  cookies which are small files installed on your machine to
                  make our website function better as well as more to your
                  liking.
                </p>
                <br />
                <h4>What Are Cookies?</h4>
                <p>
                  A cookie is a small piece of data stored on your browser when
                  you visit our website. They facilitate remembering important
                  things, such as your preferences, so that we do not have to
                  remember them each time you visit. Besides that, cookies give
                  us a more profound understanding of how you are interacting
                  with the website, which lets us serve better on this and the
                  next instance.
                </p>
                <br />

                <h4>How We Use Cookies</h4>
                <p>We employ cookies for the following purposes:</p>
                <ul>
                  <li>
                    So that you can rediscover your preferences the moment you
                    come back to our site.
                  </li>
                  <li>
                    To make our website work better for visitors so that it
                    works better for them.
                  </li>
                  <li>
                    So that our website remains secure as well as running
                    smoothly.
                  </li>
                </ul>
                <br />

                <h4>Categories of Cookies Used</h4>
                <ul>
                  <li>
                    <b>Necessary Cookies: </b>These cookies allow the proper
                    functioning of the site. The site would not function
                    entirely if they were disabled.
                  </li>
                  <li>
                    <b>Performance Cookies: </b>These help us know about the
                    usage of our website by visitors. We use this information to
                    improve and correct issues.
                  </li>
                  <li>
                    <b>Functional Cookies: </b>These cookies remember the
                    choices you have made. These are likely to include your
                    preferences regarding languages and will serve to make your
                    visit even more enjoyable using more personalized features.
                  </li>
                </ul>
                <br />

                <h4>Managing Cookies</h4>
                <p>
                  Cookies are stored on your device if you don't want this we
                  can stop them from being set. Certain features of our website
                  may not work properly with cookies disabled.
                </p>
                <br />

                <h4>Alterations to This Policy</h4>
                <p>
                  We can, at our discretion, update this Cookies Policy from
                  time to time to reflect changes to how we use cookies or where
                  applicable laws and regulations change in Hong Kong. In these
                  circumstances, we will let you know by updating this page.
                </p>
                <p>
                  If you need further clarification regarding any aspect of our
                  Cookies Policy, please drop us a line at info@zeurgies.com
                  immediately.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetailsWithSidebar;
