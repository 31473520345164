import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { IoLogoTwitter, IoLogoVimeo, IoLogoPinterest } from "react-icons/io";
import { MdExpandLess } from "react-icons/md";
// import { FaHeart } from "react-icons/fa";
import styles from "./Footer.module.scss";
import logo from "../../../assets/images/logo/Zeurgies.png";
import logoDark from "../../../assets/images/logo/v2.png";

const Footer = ({ footerBg }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <footer
      className={`footer-area ${footerBg === "white" ? styles.footer2 : ""}`}
    >
      <div
        className={`${styles.footerTop} ${
          footerBg === "white" ? "bg--cart-7" : "bg--cart-2"
        }`}
      >
        <div className="container">
          <div className="row">
            {/* Start Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-3">
              <div className={styles.singleWidget}>
                <div className={styles.logo}>
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                      src={footerBg === "white" ? logoDark : logo}
                      alt="voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <p>
                    We are your reliable friend when it comes to choosing the
                    appropriate web hosting solution for your online business.
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-2 offset-lg-1 xs__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Quick Links</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>Home</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/service"}>
                      Service
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>About</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Helps</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/cookies-policy"}>
                      Cookies Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-and-conditions"}>
                      Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
            {/* Start Single Wedget */}
            <div className="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12 sm__mt--40 md__mt--40">
              <div className={styles.singleWidget}>
                <h2 className={styles.ftTitle}>Address</h2>
                <ul className={styles.ftContactLink}>
                  <li>
                    <p style={{ color: "white" }}>
                      {" "}
                      Chinachem Golden Plaza, 77 Mody Rd, Tsim Sha Tsui, Hong
                      Kong
                    </p>
                  </li>
                  <li>
                    <p style={{ color: "white" }}>
                      <b style={{ color: "#e77965" }}>Phone:</b> +852 23115832
                    </p>
                  </li>
                  <li>
                    <p style={{ color: "white" }}>
                      <b style={{ color: "#e77965" }}>Email:</b>{" "}
                      info@zeurgies.com
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Single Wedget */}
          </div>
        </div>
      </div>
      <div
        className={`${styles.copyright}  ${
          footerBg === "white" ? "bg--cart-8" : "bg--cart-3"
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6">
              <div className={styles.copyrightInner}>
                <p className="copyright-text">
                  Copyright &copy; 2024{" "}
                  <a href="/" className="copyright-link">
                    Zeurgies
                  </a>
                  . All rights reserved.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className={styles.ftSocialLink}>
                <ul className={styles.socialIcon}>
                  <li>
                    <a
                      href="https://x.com/zeurgies"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://pinterest.com/zeurgies/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoPinterest />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </footer>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string,
};

export default Footer;
