import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
// import BlogFeatured from "../containers/blog/blog-featured";
import TermsandConditions from "../containers/blog/terms-and-conditions";

const Blog = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Terms of Service | Zeurgies</title>
        <meta
          name="description"
          content="Before you finalize your desired web hosting service, know the terms of using these services."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Terms of Service" />
        {/* blog featured */}
        {/* <BlogFeatured /> */}
        {/* blog post with sidebar */}
        <TermsandConditions />
      </LayoutOne>
    </Fragment>
  );
};

export default Blog;
