import React from "react";

const PostDetailsWithSidebar = () => {
  return (
    <div className="blog__details__area pt--120 pb--110 bg--white">
      <div className="container">
        <div className="row">
          <div>
            <div>
              <div style={{ textAlign: "justify" }}>
                <h2 style={{ fontSize: "40px" }}>Terms of Service</h2>
                <p>
                  Welcome to Zeurgies! Before you utilize our web hosting
                  services, we ask you to cautiously read the Terms of Service
                  listed below. By using our website and service, you agree to
                  these terms that establish a binding agreement between you and
                  Zeurgies.
                </p>
                <br />

                <h4>1. Acceptance of Terms</h4>
                <p>
                  By using our website or registering for our services, you
                  agree that you have read, understood, and agreed to these
                  terms. If you don't agree with any part of these terms, please
                  do not use our services.
                </p>
                <br />

                <h4>2. Services Offered</h4>
                <p>
                  Zeurgies offers a variety of web hosting. Each of its services
                  is targeted to have your website running effectively and
                  securely. Some details of our particular web hosting plans,
                  features, and services can be found on the website itself.
                </p>
                <br />

                <h4>3. Payment Norms</h4>
                <p>
                  All service rendered at Zeurgies is paid in cash, and we
                  expect full payment at the time of delivery or as agreed,
                  respecting any specific package or custom bundle arrangement.
                  We do not yet accept credit cards, online transfers, or
                  digital payments.
                </p>
                <p>
                  Failure to make timely payments results in suspension or
                  termination of services. Restoration, if your service is
                  suspended on account of nonpayment, shall be charged
                  additionally and paid in cash.
                </p>
                <br />

                <h4>4. User Obligations</h4>
                <p>With regard to our web hosting services, you agree to:</p>
                <ul>
                  <li>
                    Furnish factual and updated information upon signing up.
                  </li>
                  <li>
                    You agree to use our services only for lawful activities and
                    only in compliance with all applicable laws and regulations
                    in Hong Kong.
                  </li>
                  <li>
                    You agree not to do any activities that could harm our
                    infrastructure, including but not restricted to hacking,
                    sending of spam messages, or spreading malware.
                  </li>
                  <li>
                    We may suspend or cancel your service without prior notice
                    if we discover any misuse.
                  </li>
                </ul>
                <br />

                <h4>5. Data and Security</h4>
                <p>
                  While we take great pains to maintain the security of our
                  servers, no system is completely error-proof. You are thus
                  responsible for regularly backing up your own data. Zeurgies
                  will accept no liability whatsoever for any loss or breach of
                  data using our services, though, of course, we will do
                  everything in our power to assist in resolving any problem
                  that might arise.
                </p>
                <br />

                <h4>6. Service Interruption</h4>
                <p>
                  Our service is occasionally unavailable for a short period
                  while it is being updated, modified, or due to unforeseen
                  technical problems. We aim to limit such disruptions and,
                  insofar as possible, will advise where planned work is likely
                  to cause a disruption to our services. We cannot accept
                  liability for losses incurred by you during periods when our
                  service is unavailable.
                </p>
                <br />

                <h4>7. Variation of Services and Terms</h4>
                <p>
                  We reserve the right at any time to modify our services,
                  pricing, or terms of service without liability. Any and all
                  changes to these terms of service will be posted herein. Your
                  continued use of our services after such changes are posted
                  constitutes your acceptance of the revised terms.
                </p>
                <br />

                <h4>8. Termination of Services</h4>
                <p>
                  For your convenience, you may terminate your service at any
                  time by contacting us at info@zeurgies.com. We may at any
                  time, without limiting our other remedies, suspend or cancel
                  your service if you breach or fail to comply with any of these
                  terms.
                </p>
                <br />

                <h4>9. Governing Legislation</h4>
                <p>
                  These Terms of Service shall be governed by and construed in
                  accordance with the laws of Hong Kong. Any disputes arising
                  out of or in connection with your use of our services shall be
                  resolved in compliance with applicable local law requirements.
                </p>
                <br />

                <h4>10. Contact Information</h4>
                <p>
                  Any queries or clarifications regarding these Terms of Service
                  can be sent to info@zeurgies.com. We will be happy to give a
                  prompt response.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetailsWithSidebar;
