import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import PostDetailsWithSidebar1 from "../containers/blog/post-details-with-sidebar1";

const BlogPost = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>
          The Essentials for Having a Website Hosting Service | Zeurgies
        </title>
        <meta
          name="description"
          content="When businesses go online, they find themselves struggling with whether to opt for a web hosting service or not. We address this confusion in this blog."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Blog Post" />
        {/* post details with sidebar */}
        <PostDetailsWithSidebar1 />
      </LayoutOne>
    </Fragment>
  );
};

export default BlogPost;
