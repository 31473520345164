import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import PostDetailsWithSidebar from "../containers/blog/post-details-with-sidebar";

const BlogPost = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>The Real Need of Hosting for a Small Website | Zeurgies</title>
        <meta
          name="description"
          content="Often small websites struggle with making the choice of whether to get their websites hosted or not. We clear out this confusion through this blog. "
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Blog Post" />
        {/* post details with sidebar */}
        <PostDetailsWithSidebar />
      </LayoutOne>
    </Fragment>
  );
};

export default BlogPost;
