import React from "react";
import { Link } from "react-router-dom";
// import BlogSidebar from "../blog-sidebar";
import styles from "./BlogPostWithSidebar.module.scss";

const BlogPostWithSidebar = ({ sidebarPosition }) => {
  return (
    <div className="voopo__blog__area pb--120 pt--50">
      <div className="container">
        <div className="row">
          <div
            className={` col-12 col-lg-12 ${
              sidebarPosition && sidebarPosition === "left"
                ? "order-1 order-lg-2"
                : ""
            }`}
          >
            <div className={styles.blogListWrapper}>
              {/* Start Single Blog */}
              <div className={`${styles.blogList}`}>
                <div className={styles.thumb}>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      "/Do-You-Really-Need-Hosting-for-a-Small-Website"
                    }
                  >
                    <img
                      src={require("./../../../assets/images/blog/md2.png")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h2>
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        "/Do-You-Really-Need-Hosting-for-a-Small-Website"
                      }
                    >
                      Do You Really Need Hosting for a Small Website?
                    </Link>
                  </h2>
                  <p>
                    In the modern era, it has become a necessity to have a
                    website for your business, no matter what the size of your
                    business is. It is not that only the big and mid-level
                    businesses need to have a website, which is hosted under a
                    reliable server. To reach its full potential and attract as
                    many consumers as possible, small businesses can also have
                    websites. Often businesses that have had a brick-and-mortar
                    approach for a long time are also trying to establish their
                    online existence through a website. When these businesses
                    begin their online endeavor, they commence with a small
                    website with a minimalistic approach, showcasing their
                    offerings.
                  </p>
                </div>
              </div>
              {/* End Single Blog */}

              {/* Start Single Blog */}
              <div className={`${styles.blogList}`}>
                <div className={styles.thumb}>
                  <Link
                    to={
                      process.env.PUBLIC_URL +
                      "/Why-Do-You-Need-a-Website-Hosting-Service"
                    }
                  >
                    <img
                      src={require("./../../../assets/images/blog/md1.png")}
                      alt="voopo voip"
                    />
                  </Link>
                </div>
                <div className={styles.content}>
                  <h2>
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        "/Why-Do-You-Need-a-Website-Hosting-Service"
                      }
                    >
                      Why Do You Need a Website Hosting Service?
                    </Link>
                  </h2>
                  <p>
                    We are standing in 2024 and the whole concept of running a
                    business has changed drastically in the last five to six
                    years. Well, the recently-gone pandemic had to give us
                    something on a positive note. The digital landscape of every
                    business in every sector has transformed considerably.
                    Starting from buying a book to booking an appointment with
                    an interior designer, you can do and buy almost everything
                    online. Similar to an offline store, where every item is
                    categorically organized in different sections when you have
                    an online presence of your business, it also has to be
                    organized in a swift way so that the targeted audience can
                    navigate through it easily. So, does it mean that it’s
                    enough to have a website for your business or your blog?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostWithSidebar;
