import React from "react";
import styles from "./ContactForm.module.scss";
import Button from "../../../components/UI/button";
import contactThumb from "../../../assets/images/about/contact.png";
import { useState } from "react";

const ContactForm = () => {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [state, setState] = useState({
    message: "",
    message2: "",
    name: "",
    email: "",
    error: {
      message2: "",
      message: "",
      name: "",
      email: "",
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      error: { ...prevState.error, [name]: "" }, // Clear the error for the field being updated
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    const { message, name, email } = state;
    let hasError = false;
    const newErrors = { message: "", name: "", email: "" };

    // Validation
    if (!message) {
      newErrors.message = "Message is required.";
      hasError = true;
    }
    if (!name) {
      newErrors.name = "Name is required.";
      hasError = true;
    }
    if (!email) {
      newErrors.email = "Email is required.";
      hasError = true;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
      hasError = true;
    }

    if (hasError) {
      setState((prevState) => ({
        ...prevState,
        error: newErrors,
      }));
      return; // Stop execution if there are errors
    }

    setIsSending(true);

    // Structure the form data
    const formData = {
      name: name,
      email: email,
      message: message,
    };

    // Make the POST request to the server
    fetch("https://acomal.top/send-mail/20/", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.msg === "success") {
          setIsSent(true);
          setState({
            message2: "Message Sent Successfully!",
            name: "",
            email: "",
            message: "",
            error: {},
          });
        } else {
          setState((prevState) => ({
            ...prevState,
            message2: "Failed to send the message!",
          }));
        }
        setIsSending(false);
      })
      .catch(() => {
        setIsSending(false);
        setState((prevState) => ({
          ...prevState,
          message2: "An error occurred while sending the message",
        }));
      });
  };

  const style = {
    color: "red",
  };

  return (
    <div className="voopo__contact ptb--110">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={styles.voopoContactForm}>
              <h2>
                If you need to contact us, Please fill out the form below.
              </h2>
              <form method="post" onSubmit={handleSubmit}>
                <div className={styles.singleContactForm}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Type Your Name"
                    value={state.name}
                    onChange={handleChange}
                  />
                  {state.error.name && (
                    <span className="error" style={style}>
                      {state.error.name}
                    </span>
                  )}
                </div>
                <div className={styles.singleContactForm}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Type Your e-mail"
                    value={state.email}
                    onChange={handleChange}
                  />
                  {state.error.email && (
                    <span className="error" style={style}>
                      {state.error.email}
                    </span>
                  )}
                </div>
                <div
                  className={`${styles.singleContactForm} message`}
                  style={{ marginBottom: "50px" }}
                >
                  <textarea
                    style={{ marginBottom: 0 }}
                    name="message"
                    placeholder="Type Your Message"
                    value={state.message}
                    onChange={handleChange}
                  />
                  {state.error.message && (
                    <span className="error" style={style}>
                      {state.error.message}
                    </span>
                  )}
                </div>
                <div className={styles.contactBtn}>
                  <Button
                    type="button"
                    text={isSending ? "Sending..." : isSent ? "Sent" : "Send"}
                    disabled={isSending || isSent}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
            <div className={styles.contactThumb}>
              <img src={contactThumb} alt="voopo voip" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
