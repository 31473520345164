import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorkerRegistration from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
//   <React.StrictMode>
    <App />
//   </React.StrictMode>
);
serviceWorkerRegistration.unregister();
