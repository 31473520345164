import React from "react";
// import styles from "./PostDetailsWithSidebar.module.scss";

const PostDetailsWithSidebar = () => {
  return (
    <div className="blog__details__area pt--120 pb--110 bg--white">
      <div className="container">
        <div className="row">
          <div>
            <div>
              <div style={{ textAlign: "justify" }}>
                <h2 style={{ fontSize: "40px" }}>Privacy Policy of Zeurgies</h2>
                <p>
                  At Zeurgies, we take your trust seriously and are committed to
                  protecting your privacy. This Privacy Policy outlines our
                  practices regarding the collection, use, and retention of your
                  personal information when you visit our website and use our
                  web hosting services.
                </p>
                <br />

                <h4>Personal Information We Gather</h4>
                <p>
                  During your visit to our website or use of our services, we
                  collect the following kinds of personal information to ensure
                  a smooth and hassle-free experience. These may include:
                </p>
                <ul>
                  <li>
                    <b>Personal Information: </b>
                    This may include your first and last name, email address or
                    alias or other identifiers of a similar nature, phone number
                    when you register or contact us.
                  </li>
                  <li>
                    <b>Technical Information: </b>
                    This may include information about your IP address, browser
                    type, and any other information that enables us to see how
                    our website is accessed and used.
                  </li>
                  <li>
                    <b>Service-related Data:</b>
                    If you buy any of our web hosting plans or another service,
                    we may require further details to run your account and
                    service preferences.
                  </li>
                </ul>
                <p>
                  We collect this information from the forms you fill in,
                  cookies, and other technologies that make your browsing
                  experience much better.
                </p>
                <br />

                <h4>What Information Do We Gather?</h4>
                <p>
                  We use the gathered information for the following purposes:
                </p>
                <ul>
                  <li>
                    To make the services of web hosting you have signed up for
                    available and running.
                  </li>
                  <li>
                    To send you any news regarding the service, technical
                    support, and any other changes related to your account.
                  </li>
                  <li>
                    We use cookies to enhance our website and tailor our
                    services based on the activity of users on our platform.
                  </li>
                  <li>
                    For security purposes, we will track unauthorized access or
                    suspicious activities on our website.
                  </li>
                </ul>
                <p>
                  We will not sell or share your personal data with third
                  parties without your consent, except in cases provided for by
                  law.
                </p>
                <br />

                <h4>How We Safeguard Your Data</h4>
                <p>
                  With our security measures, which are technical as well as
                  procedural in nature, we ensure that your data is protected
                  against unauthorized access or disclosure. We achieve it
                  through the help of secure servers and adequate encryption
                  methods plus strict controls over what happens inside our
                  organization.
                </p>
                <p>
                  We cannot guarantee the privacy of your data when it is being
                  carried over the internet or otherwise; however, we will do
                  what is within our means to protect all information to the
                  greatest degree of practicability.
                </p>
                <br />

                <h4>Choices and Rights of Yours</h4>
                <p>
                  You have the right at any time, without cost to access,
                  modify, or request removal of your personal data. For changes
                  or viewing some information we hold about you, you may contact
                  us at info@zeurgies.com and we shall do what is within our
                  power to address your request promptly.
                </p>
                <p>
                  You also have control over cookies through visiting your
                  browser options and settings, which will enable you to limit
                  data sharing with us.
                </p>
                <br />

                <h4>Alterations to this Policy</h4>
                <p>
                  We may update this Privacy Policy from time to time, so you
                  should check back here regularly to see what changes we have
                  made. If we make a significant change to this Privacy Policy,
                  we will update this page with the new effective date and give
                  you notice through e-mail or on this page.
                </p>
                <p>
                  If you continue to use our website and services, you agree to
                  this Privacy Policy.
                </p>
                <br />

                <h4>Contact Us</h4>
                <p>
                  If you further clarification regarding this Privacy Policy
                  please contact us immediately at info@zeurgies.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetailsWithSidebar;
