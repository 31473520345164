import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
// import BlogFeatured from "../containers/blog/blog-featured";
import BlogPostWithSidebar from "../containers/blog/blog-post-with-sidebar";

const Blog = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Recent Blogs | Zeurgies</title>
        <meta
          name="description"
          content="Scroll through our blog page and read our articles to be aware of the latest and trending news on web hosting."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Zeurgies Blogs" />
        {/* blog featured */}
        {/* <BlogFeatured /> */}
        {/* blog post with sidebar */}
        <BlogPostWithSidebar />
      </LayoutOne>
    </Fragment>
  );
};

export default Blog;
