import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import HomeOne from "./pages/HomeOne";
// import HomeTwo from "./pages/HomeTwo";
import About from "./pages/About";
import Blog from "./pages/Blog";
// import BlogLeftSidebar from "./pages/BlogLeftSidebar";
import BlogPost from "./pages/BlogPost";
import BlogPost1 from "./pages/BlogPost1";
// import BlogPostLeftSidebar from "./pages/BlogPostLeftsSidebar";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
// import ServiceDetails from "./pages/ServiceDetails";
// import ServiceDetailsLeftSidebar from "./pages/ServiceDetailsLeftSidebar";
import NotFound from "./pages/NotFound";
import Cookies from "./pages/cookies-policy";
import Privacy from "./pages/privacy-policy";
import Terms from "./pages/terms-and-conditions";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomeOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={Blog}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL +
              "/Do-You-Really-Need-Hosting-for-a-Small-Website"
            }`}
            component={BlogPost}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL +
              "/Why-Do-You-Need-a-Website-Hosting-Service"
            }`}
            component={BlogPost1}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/service"}`}
            component={Service}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/cookies-policy"}`}
            component={Cookies}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
            component={Privacy}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/terms-and-conditions"}`}
            component={Terms}
          />
          <Route
            path={process.env.PUBLIC_URL + "/not-found"}
            component={NotFound}
          />
          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
